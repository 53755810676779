<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name" />
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="属性类别">
          <el-select
            v-model="searchData.groupCode"
            placeholder="请选择"
            size="small"
            clearable
            class="search-form-item"
          >
            <el-option
              v-for="item in dict.GoodsGroupCodeList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="属性值">
          <el-input
            v-model="searchData.attrName"
            size="small"
            placeholder="请输入"
            class="search-form-item"
          />
        </el-form-item>
        <el-form-item label=" ">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="getList"
            >搜索</el-button
          >
          <el-button
            type="info"
            size="small"
            icon="el-icon-refresh"
            @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="create-combo">
      <el-button
        type="primary"
        size="small"
        @click="openDialog('create')"
        :disabled="!isShowBtn(AUTH_BTN.goods_attr_create)"
        >新增属性</el-button
      >
    </div>
    <el-tabs v-model="searchData.goodsType" extra>
      <el-tab-pane
        v-for="status in dict.GoodsTypeList"
        :label="status.name"
        :name="status.value.toString()"
        :key="status.value"
      />
    </el-tabs>
    <tp-table
      :tableData="table.data"
      :totalNum="table.total"
      :current-page.sync="table.currentPage"
      :pageSize.sync="table.pageSize"
    >
      <el-table-column prop="id" label="ID" />
      <el-table-column prop="groupCode" label="属性类别">
        <template slot-scope="scope">
          <span>{{
            dict.GoodsGroupCodeToTextMap.get(scope.row.groupCode)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="attrName" label="属性值" />
      <el-table-column prop="weight" label="权重" />
      <el-table-column label="操作" fixed="right" width="150">
        <template slot-scope="scope">
          <div class="operations">
            <el-link
              type="primary"
              @click="
                openDialog('edit', {
                  id: scope.row.id,
                  groupCode: scope.row.groupCode,
                  attrName: scope.row.attrName,
                  weight: scope.row.weight,
                })
              "
              :underline="false"
              v-if="!scope.row.status"
              :disabled="!isShowBtn(AUTH_BTN.goods_attr_edit)"
              >编辑</el-link
            >
          </div>
        </template>
      </el-table-column>
    </tp-table>
    <goods-brand-detail
      :visible.sync="dialogConfig.visible"
      :id="dialogConfig.id"
      :mode="dialogConfig.mode"
      :goodsType="Number(searchData.goodsType)"
      :groupCode="dialogConfig.groupCode"
      :attrName="dialogConfig.attrName"
      :weight="dialogConfig.weight"
      @refreshList="getList"
    />
  </div>
</template>

<script>
import { authBtnMixin } from "@/mixins/authBtnMixin";
import {
  EnumGoodsType,
  GoodsTypeList,
  GoodsGroupCodeList,
  GoodsGroupCodeToTextMap,
} from "@/enum/dict/index.js";

import { getGoodsAttrPage } from "./api.js";
import GoodsBrandDetail from "./detail.vue";

export default {
  name: "GoodsAttrList",
  mixins: [authBtnMixin],
  components: {
    GoodsBrandDetail,
  },
  data() {
    return {
      searchData: {
        goodsType: EnumGoodsType.C3.toString(),
        groupCode: undefined,
        attrName: undefined,
      },
      dict: {
        GoodsGroupCodeList,
        GoodsGroupCodeToTextMap,
        GoodsTypeList,
      },
      table: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
        data: [],
      },
      dialogConfig: {
        mode: "create",
        id: null,
        visible: false,
        groupCode: null,
        attrName: null,
        weight: null,
      },
    };
  },
  provide() {
    return {
      context: this,
    };
  },
  watch: {
    "table.currentPage"() {
      this.getList();
    },
    "page.pageSize"() {
      this.getList();
    },
    "searchData.goodsType"() {
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },

  methods: {
    async getList() {
      const res = await getGoodsAttrPage({
        ...this.searchData,
        page: this.table.currentPage,
        pageSize: this.table.pageSize,
      });

      this.table.data = res.list || [];
      this.table.total = res.total || 0;
    },
    reset() {
      this.$set(this, "searchData", this.$options.data().searchData);

      this.getList();
    },
    openDialog(mode, detail = null) {
      this.dialogConfig = { mode, visible: true, ...detail };
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
}

.search-form-item {
  width: 220px;

  /deep/ {
    input {
      text-align: left;
    }
  }
}

.create-combo {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.operations {
  display: flex;
  gap: 8px;
}
</style>
