import { http } from "@/http/axios.js";
// 获取商品属性列表
export function getGoodsAttrPage(params) {
  return http({
    url: "/admin/store/goods/attr/page",
    method: "get",
    params,
  });
}

// 获取商品属性详情
export function getGoodsAttrDetail(id) {
  return http({
    url: "/admin/store/goods/attr/detail",
    method: "get",
    params: { id },
  });
}

// 新增商品属性
export function addGoodsAttr(data) {
  return http({
    url: "/admin/store/goods/attr/add",
    method: "post",
    data,
  });
}

// 编辑商品属性
export function editGoodsAttr(data) {
  return http({
    url: "/admin/store/goods/attr/edit",
    method: "post",
    data,
  });
}
